import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/notifications";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, NOTIFICATION } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form";
import { customer_name_list } from "../../actions/customer";
const CreateNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const login_details = JSON.parse(sessionStorage.getItem("user"))
  const isEdit = location?.state?.isEdit;
  const { notification_create } = bindActionCreators(Action, dispatch);

  const [file, setFile] = useState(null);
  const [form, setForm] = useState({});
  useEffect(()=>{
    dispatch(customer_name_list())
  },[])
    const customer = useSelector((state)=>state?.customer?.customer_name)
    const message = useSelector((state)=> state?.notification?.notification)
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    const tempForm = form;
    tempForm["tempuser"] = form?.["user"];
    tempForm["tempnotification_image"] = form["notification_image"];
    if(location?.state?.customer && location?.state?.send =="nofitication"){
      tempForm["user"] = location?.state?.customer
    }else{

      if(form?.user_type == "All Customer"){
        tempForm["receiver_type"] = "All";
      }else{
        tempForm["user"] = customer?.data
            ?.map(
              (option) =>
                form?.["user"]?.includes(
                  option.name
                ) && option.id
            )
            .filter((e) => e);
      }
    } 

    setForm({
      ...tempForm,
    });
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("master",login_details?.id?.id)
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    const createNotificationApi = await notification_create(data);
    if (createNotificationApi?.statuscode == 200) {
      toast.success(createNotificationApi?.message);
      setTimeout(() => {
        navigate("/notification-list");
        dispatch({ type: NOTIFICATION, payload: undefined });
      }, 1000);
      setForm({});
    } else {
      toast.error(createNotificationApi?.message);
    }
  };

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "inputBox",
      size: "4",
      title: "Notification Title",
      name: "notification_title",
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Select User Type",
      size: "4",
      name: "user_type",
      options: ["All Customer", "Select Customer"],
      required: true,
    },
    {
      id: "3",
      type: "select_multiple",
      title: "Select User",
      size: "4",
      name: "user",
      options: [],
      required: true,
      display :"none"
    },

    {
      id: "4",
      type: "inputBox",
      title: "Description",
      name: "description",
      maxLength: "100",
      required: true,
    },
    {
      id: "5",
      type: "image",
      title: "Notification Image",
      name: "notification_image",
      subtitle: "(Resolution : 910px x 1280px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
    },
    {
      id: "8",
      type: "button",
      title: "Send",
    },
  ]);
  const formTitle = isEdit ? "Edit Category" : "Create Category";
  useMemo(()=>{
    if(location?.state?.customer && location?.state?.send =="nofitication"){
      const temp = formStructure
      temp[1]["display"] = "none"
      setFormStructure({...temp})
    }else{
      const temp = formStructure
      temp[1]["display"] = "block"
      setFormStructure({...temp})
    }
  },[location?.state?.customer])
  useMemo(() => {
    if(form?.user_type == "Select Customer" ){
        const temp = formStructure
        temp[2]["display"] = "block"
        setFormStructure([...temp])
    } else{
        const temp = formStructure
        temp[2]["display"] = "none"
        setFormStructure([...temp])
    }
  }, [form?.user_type]);
  useMemo(()=>{
    if(customer?.statuscode == 200 ){

        const temp = formStructure
        temp[2]["options"] = location?.state?.send == "nofitication" ?[]: customer?.data?.map((ele)=>ele?.name)
        setFormStructure([...temp])
    }
},[customer])
useMemo(() => {
  // console.log(message,"Print SMS123")
  if (message?.statuscode == 200) {

    setForm({});
    setTimeout(() => {
      const data = new FormData();
      data.append("id", login_details?.id?.id);
      dispatch(Action.all_notification_list(data));
      dispatch(
        Action.notification_create_mask({
          user: message?.user,
          master: message?.master,
          notification_title: message?.notification_title,
          description: message?.description,
          notification_image: message?.notification_image,
          notification_id: message?.notification_id,
        })
      );
      dispatch({ type: NOTIFICATION, payload: undefined });
      navigate("/notification-list");
    
    }, 1000);
  } else {
    const temp = form;
    temp["user"] = form["tempuser"];
    temp["notification_image"] = form["notification_image"];
    setForm({ ...temp });
  }
}, [message]);
  return (
    <div>
      <Breadcrumbs
        parent="Category"
        title={isEdit ? "Edit Category" : "Create Category"}
        mainTitle={isEdit ? "Edit Category" : "Create Category"}
      />

      <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CreateNotification;
